<template>
    <div class="main">
        <Navbar
            :items="$store.state.navigation.body"
            home-link="/"
            :fav-icon="require(`~/static/${$store.state.prismicLang}-logo.svg`)"
        />

        <Section width="lg" class="margin-top-10">
            <div class="flex">
                <h1>404</h1>
                <h5 class="margin-left-5 padding-left-5 gray border-left">Requested page not found.</h5>
            </div>
        </Section>
    </div>
</template>
<script>
import { Navbar } from '@swegaming-ab/vue-components';
export default {
    components: {
        Navbar
    },
    async middleware({ store, $prismic, route }) {
        await store.dispatch('fetchStates', {$prismic, route});
    }
};
</script>
