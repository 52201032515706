<template>
    <div class="bar-container flex">
        <div class="bar-slot" :class="{ green: show }">
            <slot />
        </div>

        <div
            class="bar"
            :class="'bg-' + background"
            @mouseenter="show = true"
            @mouseleave="show = false"
        >
            <div
                class="bar-progress"
                :style="{ width: barWidth + '%' }"
            />

            <div
                v-show="show"
                class="bar-text"
            >
                {{ hoverText }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        width: {
            type: Number,
            required: true,
            default: Number
        },
        background: {
            type: String,
            required: false,
            default: 'lgray'
        },
        hoverText: {
            type: String,
            required: false,
            default: String
        }
    },
    data () {
        return {
            show: false
        };
    },
    computed: {
        barWidth () {
            return this.width * 10;
        }
    }
};
</script>
