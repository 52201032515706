import Vue from 'vue';

Vue.prototype.$helpers = {

    // create helpers that you can access
    // through the whole application
    filterEmptyRelations: (relation) => {
        let key = Object.keys(relation)[0];
        return relation[key] && relation[key].id;
    },

    filterEmptyElements: (element) => {
        let key = Object.keys(element)[0];
        return element[key];
    },
    
    getRedirectUrl: (card, placement, position) => {

        if (placement && position && position > 0) {
            return `/out/${card.uid}/?placement=${placement}&position=${position}`;
        }

        if (placement) {
            return `/out/${card.uid}/?placement=${placement}`;
        }

        if (position && position > 0) {
            return `/out/${card.uid}/?position=${position}`;
        }
        
        return `/out/${card.uid}/`;
    },

    getCardStarsCount: (card) => {
        if (card.data.overwrite_stars) {
            return card.data.overwrite_stars;
        }
        return Math.round(card.data.core_api.ratings.average_score);
    },

    makeH3Text: (prop) => {
        if (Array.isArray(prop)) {
            if (prop[0] && prop[0].text) {
                return [{ 'type': 'heading3', 'text': prop[0].text, 'spans': [] }];
            }
            return prop;
        }

        return [{ 'type': 'heading3', 'text': prop, 'spans': [] }];
    },

    makeRichText: (prop) => {
        if (Array.isArray(prop)) {
            return prop;
        }
        return [{ 'type': 'paragraph', 'text': prop, 'spans': [] }];
    },

    formatId: (id) => {
        return id.replace('$', '-');
    }
};
