<template>
    <div class="main">
        <Navbar
            :items="$store.state.navigation.body"
            home-link="/"
            :fav-icon="require(`~/static/${$store.state.prismicLang}-logo.svg`)"
            :breakpoint="900"
        >
            <nav-search />
        </Navbar>

        <Nuxt />

        <client-only>
            <CookieConsent
                :button-text="$translate('cookies_button')"
                :content="$translate('cookies_text')"
            />
        </client-only>

        <CompareCardsPopOut />

        <!-- <ScrollTop /> -->

        <Footer />
    </div>
</template>
<script>
import { CookieConsent } from '@swegaming-ab/vue-components';
import { Navbar } from '@swegaming-ab/vue-components';
import NavSearch from '@/components/NavSearch.vue';
import Footer from '@/components/includes/TheFooter';
import CompareCardsPopOut from '@/components/CompareCardsPopOut';
export default {
    components: {
        Navbar,
        NavSearch,
        CookieConsent,
        Footer,
        CompareCardsPopOut
    },
    async middleware({ store, $prismic, route }) {
        await store.dispatch('fetchStates', {$prismic, route});
    }
};
</script>
