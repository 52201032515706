<template>
    <transition name="slide-left">
        <div v-if="show" class="compare-cards bg-green flex">
            <div class="width-full flex text-center padding-2 bg-white">
                <p class="text-bold">{{ $translate('compare_header', 'Valda kreditkort') }}</p>
            </div>

            <ul class="width-full padding-2 bg-lgray">
                <li
                    v-for="(cardId, index) in cardsToCompare"
                    :key="index"
                    class="flex flex-x-start flex-keep"
                >
                    <nuxt-link :to="$prismic.linkResolver(card[cardId])" class="flex-1 flex flex-keep">
                        <PrismicImage
                            w="40"
                            h="25"
                            :img="card[cardId].data.image"
                        />

                        <span class="flex-1 black margin-left-2">{{ card[ cardId ].data.title }}</span>
                    </nuxt-link>

                    <div class="remove flex bg-white margin-left-2" @click="remove(cardId)">
                        <i class="fas fa-times red" />
                    </div>
                </li>
            </ul>

            <div class="width-full flex padding-2 bg-green">
                <nuxt-link
                    :to="setCardsToCompare()"
                    class="btn btn-sm btn-white"
                    :class="{disabled: count <= 1}"
                >
                    {{ $translate('compare_button', 'Jämför kreditkort') }} ({{ count }} st)
                </nuxt-link>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    data () {
        return {
            expand: false
        };
    },
    computed: {
        show () {
            return this.count > 0;
        },

        count () {
            return this.$store.state.cardsToCompare.length;
        },

        cardsToCompare () {
            return this.$store.state.cardsToCompare;
        },

        card () {
            return this.$store.state.cards;
        }
    },
    methods: {
        remove(id) {
            this.$store.commit('REMOVE_CARD_FROM_COMPARE', id);
        },

        setCardsToCompare() {
            let cardIds = [];

            for (let i = 0; i < this.$store.state.cardsToCompare.length; i++) {
                let id = this.$store.state.cardsToCompare[i];
                cardIds.push(id);

                var idString = Object.keys(cardIds).map(function(key) {
                    return cardIds[key];
                }).join('&card=');
            }

            return `/${this.$store.state.settings.compare_cards_slug ?? 'jamfor-kreditkort'}/?card=${idString}`;
        }
    }
};
</script>
