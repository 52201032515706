<template>
    <div class="tooltip" />
</template>

<script>
export default {
    data () {
        return {
            show: false
        };
    }
};
</script>
