<template>
    <footer class="flex">
        <div v-if="$store.state.settings.newsletter_signup_enable" class="width-full newsletter text-center flex">
            <div v-if="! submitted" class="inner section-width-sm">
                <span class="h5 text-bold">{{ $store.state.settings.newsletter_signup_header }}</span>
                <p>{{ $store.state.settings.newsletter_signup_p }}</p>
                <div class="padding-4 flex newsletter-input-wrapper">
                    <input 
                        v-model="email"
                        type="text"
                        class="br-5 margin-0"
                        :class="{error: emailError}"
                        :placeholder="$store.state.settings.newsletter_signup_email_placeholder"
                    >
                    <a class="btn btn-sm btn-blue" @click="submit">{{ $store.state.settings.newsletter_signup_button_text }}</a>
                </div>
                <p class="terms">{{ $store.state.settings.newsletter_signup_terms }}</p>
            </div>
            <div v-else class="inner section-width-sm">
                <span class="h5 text-bold">{{ $store.state.settings.newsletter_signup_success_text }}</span>
            </div>
        </div>
        <div class="width-full padding-4 flex markets">
            <div class="section-width-sm flex">
                <a 
                    v-for="(locale, index) in locales" 
                    :key="index" 
                    :href="locale.domain" 
                    class="markets-market flex margin-x-4"
                >
                    <img :src="locale.icon" alt="" loading="lazy">
                    <span class="white margin-left-2">{{ locale.country }}</span>
                </a>
            </div>
        </div>

        <div class="inner section-width-sm flex flex-y-start">
            <div class="col flex-1 white short-info flex m-center" v-html="$prismic.asHtml(footer.company_short_info)" />

            <ul class="col flex-1">
                <li class="head">{{ $translate('information', 'Information') }}</li>
                <li v-for="(link, index) in footer.link_column_1" :key="index">
                    <nuxt-link :to="$prismic.linkResolver(link.link)">{{ link.link_text }}</nuxt-link>
                </li>
            </ul>

            <ul v-if="posts.length > 0" class="col flex-1 white">
                <li class="head">{{ $translate('news', 'Nyheter') }}</li>
                <li v-for="(link, index) in posts" :key="index">
                    <nuxt-link :to="$prismic.linkResolver(link)">{{ link.data.title }}</nuxt-link>
                </li>
            </ul>
        </div>

        <div class="container width-full padding-6 flexx text-center info">
            <div
                v-for="(box, index) in footer.info_boxes"
                :key="index"
                class="flex info-inner m-center text-left"
                v-html="$prismic.asHtml(box.content)"
            />
        </div>
    </footer>
</template>
<script>
import locales from '@/config/locales.config.js';
export default {
    data () {
        return {
            locales: locales,
            email: '',
            submitted: false,
            emailError: false,
            posts: []
        };
    },
    async fetch() {
        const predicatesPosts = [
            this.$prismic.predicates.at('document.type', 'post'),
        ];
        this.posts = (await this.$prismic.api.query(predicatesPosts,
            { 
                orderings: '[document.first_publication_date desc]',
                lang: process.env.PRISMIC_LANG,
                pageSize: 7
            }
        )).results;
    },
    fetchKey: 'the-footer',
    computed: {
        footer () {
            return this.$store.state.footer;
        }
    },
    methods: {
        submit() {
            this.emailError = false;
            const validEmailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
            if (this.email.match(validEmailRegex)) {
                this.$axios
                    .post('https://api.broker.compary.com/users', {
                        email: this.email,
                        guide_id: process.env.BROKER_GUIDE_ID,
                        tracking: this.$tracking.getTrackingObject(),
                        redirect_url: null
                    })
                    .then(() => {
                        this.submitted = true;
                    });
            }
            else {
                this.emailError = true;
            }
        }
    }
};
</script>
