import locales from '../config/locales.config.js';

export default function (document, withDomain = false) {
    try {

        const documentsMap = require('@/documents.json');
        const documentHelper = documentsMap[document.id];
        const slug = documentHelper.slug;

        if (withDomain) {
            return `${getDomain(document)}${slug}`;
        }

        return slug;
    }
    catch (e) {
        return '/undefined';
    }
}

function getDomain(document) {
    for (let i = 0; i < locales.length; i++) {
        if (locales[i].prismic === document.lang) {
            return locales[i].domain;
        }
    }
}
