
export default [
    {
        country: 'Sweden',
        domain: 'https://www.alltomkreditkort.se',
        prismic: 'sv-se',
        hreflang: 'sv-se',
        icon: '/flags/sv.png'
    },
    {
        country: 'Norway',
        domain: 'https://www.bestekredittkortet.com',
        prismic: 'no',
        hreflang: 'no-no',
        icon: '/flags/no.png'
    },
    // {
    //     country: 'Spain',
    //     domain: 'https://www.lastarjetas.es',
    //     prismic: 'es-es',
    //     hreflang: 'es-es',
    //     icon: '/flags/es.png'
    // }
];
