export default ({ app }, inject) => {
    const event = {
        cardGuideSubmitted: () => {
            app.$gtm.push({
                event: 'cardGuideSubmitted',
            });
        },
    };
    inject('event', event);
};
