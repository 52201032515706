// import Vue from 'vue';
import { size, keyBy } from 'lodash';
import { replace as replaceHelper, queryAll } from '@swegaming-ab/nuxtjs-helpers';

export const state = () => ({
    settings: {},
    navigation: {},
    footer: {},
    translations: {},
    lists: [],
    cards: [],
    cardsToCompare: [],
    authors: [],
    prismicLang: process.env.PRISMIC_LANG
});

export const mutations = {
    SET_STATE(state, {key, value}) {
        state[key] = replaceHelper(value);
    },

    ADD_CARD_TO_COMPARE(state, cardId) {
        state.cardsToCompare.push(cardId);
    },

    REMOVE_CARD_FROM_COMPARE(state, cardId) {
        for (let i = 0; i < state.cardsToCompare.length; i++) {
            if (state.cardsToCompare[i] === cardId) {
                state.cardsToCompare.splice(i, 1);
                break;
            }
        }
    },

    CLEAR_CARDS_TO_COMPARE(state) {
        state.cardsToCompare.splice(0);
    }
};

export const actions = {
    async fetchStates({ commit }, {$prismic}) {
        try {
            if (size(this.state.translations) === 0) {
                const translations = (await $prismic.api.getSingle('translations',
                    { lang: process.env.PRISMIC_LANG }
                ));

                Object.keys(translations.data).forEach(key => {
                    if (! translations.data[key]) {
                        delete translations.data[key];
                    }
                });

                commit('SET_STATE', {
                    key: 'translations',
                    value: translations.data
                });
            }

            if (size(this.state.navigation) === 0) {
                const navigation = (await $prismic.api.getSingle('navigation',
                    { lang: process.env.PRISMIC_LANG }
                ));

                navigation.data.body.forEach(slice => {
                    if (slice.slice_type === 'dropdown_menu') {
                        slice.items.forEach(item => {
                            cleanUpLinkData(item.ddm_link);
                        });
                    }
                });

                commit('SET_STATE', {
                    key: 'navigation',
                    value: navigation.data
                });
            }

            if (size(this.state.footer) === 0) {
                const footer = (await $prismic.api.getSingle('footer',
                    { lang: process.env.PRISMIC_LANG }
                ));

                commit('SET_STATE', {
                    key: 'footer',
                    value: footer.data
                });
            }

            if (size(this.state.settings) === 0) {
                const settings = (await $prismic.api.getSingle('settings',
                    { lang: process.env.PRISMIC_LANG }
                ));

                commit('SET_STATE', {
                    key: 'settings',
                    value: settings.data
                });
            }

            if (size(this.state.lists) === 0) {
                const lists = await queryAll($prismic.api,
                    $prismic.predicates.at('document.type', 'list'),
                    {lang: process.env.PRISMIC_LANG}
                );

                lists.forEach(list => {

                    cleanUpDocumentData(list);
                    delete list.lang;
                    delete list.uid;

                    list.data.cards.forEach(iterator => {
                        cleanUpLinkData(iterator.card);
                    });

                });

                if (size(lists) > 0) {
                    commit('SET_STATE', {
                        key: 'lists',
                        value: keyBy(lists, 'id')
                    });
                }
            }

            if (size(this.state.cards) === 0) {

                const cards = await queryAll($prismic.api,
                    $prismic.predicates.at('document.type', 'credit_card'),
                    {lang: process.env.PRISMIC_LANG}
                );

                cards.forEach(card => {

                    cleanUpDocumentData(card);

                    delete card.data.seo_title;
                    delete card.data.seo_description;
                    delete card.data.content;
                    delete card.data.short_summary;
                    delete card.data.faq;

                    delete card.data.core_api.core_data.source_sales_stats;

                });

                const randomizedPartnerCards = cards
                    .filter(card => card.data.redirect_enabled)
                    .map(card => card.id)
                    .sort(() => 0.5 - Math.random());

                cards.map(card => {
                    const validRandomizedPartnerCards = randomizedPartnerCards
                        .filter(id => id !== card.id);
                    const start = Math.random() * (validRandomizedPartnerCards.length - 3);
                    card.otherCardIds = validRandomizedPartnerCards.slice(start, start + 3);
                    return card;
                
                });

                if (size(cards) > 0) {
                    commit('SET_STATE', {
                        key: 'cards',
                        value: keyBy(cards, 'id')
                    });
                }
            }

            if (size(this.state.authors) === 0) {
                const authors = await queryAll($prismic.api,
                    $prismic.predicates.at('document.type', 'author'),
                    {lang: process.env.PRISMIC_LANG}
                );

                authors.forEach(author => {

                    cleanUpDocumentData(author);
                    delete author.lang;

                    delete author.data.seo_title;
                    delete author.data.seo_description;
                    delete author.data.page_preamble;
                    delete author.data.page_content;

                });

                if (size(authors) > 0) {
                    commit('SET_STATE', {
                        key: 'authors',
                        value: keyBy(authors, 'id')
                    });
                }
            }


        }
        catch (e) {
            console.log(e);
        }
    }
};


const cleanUpDocumentData = (document) => {
    delete document.url;
    delete document.href;
    delete document.tags;
    delete document.slugs;
    delete document.linked_documents;
    delete document.alternate_languages;
    delete document.first_publication_date;
    delete document.last_publication_date;
};

const cleanUpLinkData = (link) => {
    delete link.tags;
    delete link.lang;
    delete link.slug;
    delete link.first_publication_date;
    delete link.last_publication_date;
    delete link.isBroken;
};