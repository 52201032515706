export default ({ app }, inject) => {
    inject('isSweden', isSweden);
    inject('isNorway', isNorway);
};

const getPrismicLang = () => {
    return process.env.PRISMIC_LANG;
};

const isSweden = () => {
    return getPrismicLang() === 'sv-se';
};
const isNorway = () => {
    return getPrismicLang() === 'no';
};