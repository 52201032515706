<template>
    <a
        v-if="enabled"
        :href="$helpers.getRedirectUrl(link, placement, position)"
        target="_blank"
        class="btn"
        :class="[
            'btn-' + background,
            'btn-' + size,
            { compliance: $store.state.settings.norway_compliance }
        ]"
        @click="$emit('click')"
    >
        {{ text }}
        <i
            v-if="icon"
            :class="icon"
            class=""
        />

        <div
            v-if="$store.state.settings.norway_compliance"
            class="compliance-text"
        >
            Annonslenke
        </div>
    </a>
</template>
<script>
export default {
    props: {
        background: {
            type: String,
            default: '',
            required: false
        },
        size: {
            type: String,
            default: '',
            required: false
        },
        icon: {
            type: String,
            required: false,
            default: String
        },
        link: {
            type: Object,
            required: true,
            default: () => {}
        },
        text: {
            type: String,
            required: false,
            default: 'Click me!'
        },
        placement: {
            type: String,
            required: false,
            default: null
        },
        position: {
            type: Number,
            required: false,
            default: null
        }
    },
    computed: {
        enabled() {
            return this.link.type === 'lender' ? 
                Boolean(this.link.data.redirect) :
                this.link.data.redirect_enabled;
        }
    }
};
</script>
<style lang="scss" scoped>
    a.compliance {
        position: relative;
        margin-bottom: 30px;

        .compliance-text {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: -25px;
            color: #999;
            font-weight: normal;
            font-family: 'Nunito Sans', sans-serif;
            font-size: 13px;
            font-weight: lighter;
        }
    }
</style>
