<template>
    <div class="search">
        <i class="fas toggle" :class="!show ? 'fa-search' : 'fa-search-minus'" @click="toggle()" />
        <transition name="slide-left">
            <div v-show="show" class="search-dropdown">
                <input 
                    ref="searchInput"
                    v-model="query"
                    type="text"
                    :placeholder="$translate('card_search_placeholder', 'Sök efter kort')"
                    @keydown.esc="toggle()"
                >
                <div v-if="query.length > 2" class="result-box">
                    <ul v-if="resultCardList.length" class="result-list">
                        <li v-for="(card, index) in resultCardList" :key="index">
                            <nuxt-link :to="$prismic.linkResolver(card)" class="result-item">
                                <prismic-image 
                                    :img="card.data.image"
                                    w="45"
                                />
                                <span>{{ card.data.title }}</span>
                            </nuxt-link>
                        </li>
                    </ul>
                    <div v-else class="no-results">
                        {{ $translate('card_search_no_results', 'Inga resultat') }}
                    </div>
                </div>
            </div>
        </transition>
        <div
            v-if="show"
            class="search-overlay"
            @click="toggle()"
        />
    </div>
</template>

<script>
export default {
    data() {
        return {
            show: false,
            query: '',
            cards: this.$store.state.cards,
        };
    },
    computed: {
        resultCardList() {
            return Object.values(this.cards).filter(card => {
                return card.data.title
                    .toLowerCase()
                    .includes(this.query.toLowerCase());
            });
        }
    },
    watch: {
        show() {
            if (this.show) {
                this.$nextTick(() => {
                    this.$refs.searchInput.focus();
                });
            }
        },
        '$route'() {
            this.show = false;
            this.query = '';
        }
    },
    methods: {
        toggle() {
            this.show = !this.show;
        }
    }
};
</script>