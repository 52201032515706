export default ({ app }, inject) => {
    
    const getOrderedList = (list) => {
        
        if (!Array.isArray(list)) {
            list = Object.values(list);
        }

        let arr = []; 
        let maxSalesCount = 0;
        let maxConversionRate = 0;
        let maxEpc = 0;

        list.forEach(l => {
            const card = Object.assign({}, app.store.state.cards[l.id]);
            card.model = new app.$models.CreditCard(card);
    
            maxEpc = card.model._core.sales_stats.epc > maxEpc ? card.model._core.sales_stats.epc : maxEpc;
            maxSalesCount = card.model._core.sales_stats.sales > maxSalesCount ? card.model._core.sales_stats.sales : maxSalesCount;
            maxConversionRate = card.model._core.sales_stats.conversion_rate > maxConversionRate ? card.model._core.sales_stats.conversion_rate : maxConversionRate;
    
            arr.push(card);
        });

        arr.forEach(card => {
    
            if (card.model._core.sales_stats.sales === 0) {
                card.order = card.model._core.sales_stats.clicks * -1;
            }
            else if (card.model._core.sales_stats.sales < 5) {
                card.order =
                    card.model._core.sales_stats.epc / maxEpc +
                    card.model._core.sales_stats.sales / maxSalesCount +
                    card.model._core.sales_stats.conversion_rate / maxConversionRate;
            }
            else {
                card.order =
                    (8 * (card.model._core.sales_stats.epc / maxEpc)) +
                    card.model._core.sales_stats.sales / maxSalesCount +
                    card.model._core.sales_stats.conversion_rate / maxConversionRate;
            }
        });
    
        return arr.slice(0).sort((a, b) => b.order - a.order);
    };
    inject('getOrderedList', getOrderedList);
};