<template>
    <div 
        v-if="! $store.state.settings.norway_compliance"
        class="rating-stars"
    >
        <i
            v-for="i in 5"
            :key="i"
            :class="{ yellow: i <= roundAverageScore }"
            class="fas fa-star"
            :style="{ 'fontSize': starSize + 'px' }"
        />
    </div>
</template>

<script>
export default {
    props: {
        averageScore: {
            type: String,
            required: true
        },
        // Recieves pixels
        starSize: {
            type: String,
            required: false,
            default: '16'
        }
    },
    computed: {
        roundAverageScore() {
            return Math.round(parseInt(this.averageScore)); 
        }
    }
};
</script>
